<template>
    <div style="background-color: #f2f2f2; margin-top: 8px">
        <el-card style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: right" size="small" label-width="85px">
                <el-row style="margin-top: 16px">
                    <!--机构类型和机构列表组件-->
                    <ef-dept-type-and-dept-select
                        @selectDept="setDept"
                        @selectDeptType="setDeptType"
                        :componentLoadingCompleteFlag.sync="pageComponentLoadingCompleteFlag"
                    />
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card style="margin-top: 8px; text-align: left">
            <el-button
                type="primary"
                @click="handleManualQuery"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.grossProfit.open')"
            >
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.report.wma.cost.change.grossProfit.export')"
            >
                导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card style="margin-top: 8px">
            <el-table
                id="table"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
                show-summary
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column
                    prop="deptName"
                    label="机构名称"
                    width="180"
                    fixed="left"
                    v-if="showColumn('deptName')"
                />
                <el-table-column prop="tradeCount" label="净销售数量" width="120" v-if="showColumn('tradeCount')" />
                <el-table-column prop="tradeMoney" label="销售净额（元）" width="150" v-if="showColumn('tradeMoney')" />
                <el-table-column prop="tradeCost" label="销售成本（元）" width="150" v-if="showColumn('tradeCost')" />
                <el-table-column
                    prop="changedStockCost"
                    label="手动调整成本（元）"
                    width="150"
                    v-if="showColumn('changedStockCost')"
                />
                <el-table-column
                    prop="grossProfit"
                    label="销售毛利（元）"
                    width="150"
                    v-if="showColumn('grossProfit')"
                />
                <el-table-column
                    prop="grossProfitRate"
                    key="grossProfitRate"
                    label="毛利率（%）"
                    width="150"
                    v-if="showColumn('grossProfitRate')"
                />
            </el-table>
            <ef-pagination
                :total="total"
                :default-limit="form.limit"
                @pageChange="pageChange"
                :sizes-style-flag="false"
                :current-page="form.page"
            />
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
    </div>
</template>

<script>
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import Util from '@/js/Util';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'WmaDeptGrossProfit',
    components: { EfPagination, EfDeptTypeAndDeptSelect, EfStartDate, EfEndDate, CheckTableShowColumnDialog },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            form: {
                deptType: '',
                deptCode: null,
                startTime: this.formatDate(new Date()),
                endTime: this.formatDate(new Date()),
                search: '',
                page: 1,
                limit: 10,
            },
            total: 0,
            tableData: [],
            pageComponentLoadingCompleteFlag: false,
            wmaBizType2Desc: Util.allWmaBizType2Desc(),
        };
    },
    filters: {
        bizTypeDesc(bizType) {
            return Util.bizTypeDesc(bizType);
        },
    },
    mounted() {
        Util.setIntervalAndTimeout(
            () => {
                return this.pageComponentLoadingCompleteFlag;
            },
            () => {
                return true;
            },
            () => {
                this.handleQuery();
            },
            3000
        );
    },
    methods: {
        async handleQuery() {
            if (!this.pageComponentLoadingCompleteFlag) {
                //只要有一个false 就代表有组件未加载完成
                return;
            }
            const rst = await this.$efApi.wmaStockCostChangeReportApi.deptGrossProfitList(this.form);
            this.tableData = rst.data;
            this.total = rst.count;
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        filterCostSum(bizTypeCostSums, bizType) {
            return bizTypeCostSums.find(function (e) {
                return bizType === e.bizType;
            });
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.handleQuery();
        },
        handleExport() {
            UrlUtils.Export(
                this,
                '移动加权机构毛利',
                `${this.$efApi.wmaStockCostChangeReportApi.baseUrl}/exportDeptGrossProfitList`,
                this.form
            );
        },
        handlePrint() {
            this.printHTML('table', this.$route.meta.title);
        },
    },
};
</script>
